/**
 * Here are all the Backend api calls for our modules
 */
import appApiClient from '../app-api-client'

const pageUriPrefix = "/stock";

export default {

    getIndexData() {
        return appApiClient().get(pageUriPrefix+'/index');
    },


    getStockOrderInfoData(id) {
        return appApiClient().get(pageUriPrefix + '/stock-orders/get-info/' + id);
    },

    getStockOrderDocumentDownloadUrlData(id) {
        return appApiClient().get(pageUriPrefix + '/stock-orders/get-document-download-url/' + id);
    },

    getInventoryInfoData(id) {
        return appApiClient().get(pageUriPrefix + '/inventories/get-info/' + id);
    },

    getInventoryDocumentDownloadUrlData(id) {
        return appApiClient().get(pageUriPrefix + '/inventories/get-document-download-url/' + id);
    },
}